import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css'
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { authLogin, getCountryList } from "../Api/authCrud";
import { ErrorMessage, SuccessMessage } from "../../../components/CustomAlert";
import { HTTP_CODE } from "../../../components/ApiConst";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useLocation } from "react-router-dom";
import toplogo from "../../../assets/Images/toplogo.png";
import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import './verification_code_input/style.css'
import { CircularProgress } from "@mui/material";
import { color, style } from "@mui/system";
import { getCountryDataById } from '../../../ui-common/helpers/Language';

function Login(props) {
  const initialValues = {
    password: "",
    username: "",
    email: "",
  };

  const phoneInputRef = useRef("");

  // useEffect(() => {
  //   phoneInputRef.current.focus();
  // }, []);
  const { onLoginSuccess, onLoginFailed, org_instance_id, intl, } = props;
  const [ipadd, setipadd] = useState(null);
  const [useagent, setuseagent] = useState(null);
  const [showPhnnumInput, setShowPhnnumInput] = useState(true);
  const [showUsernameInput, setShowUsernameInput] = useState(false);
  const [fetchCountryId, setFetchCountryId] = React.useState("");

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const { t, i18n } = useTranslation();
  const org_country_id = localStorage.getItem("org_country_id");
  const countryData = getCountryDataById(org_country_id);
  const CountryDialCode = countryData?.dial_country_code;
  const [phoneNumber, setPhoneNumber] = useState('');
  const showUserDeleteError = (data) => {
    toast.error(data, {
      position: "top-right",
      autoClose: false,
      // hideProgressBar: true,
      closeOnClick: true,
    });
  };


  const handlePasswordChange = (evnt) => {
    setPasswordInput(evnt.target.value);

    formik.setFieldValue("password", evnt.target.value)
  };
  const togglePassword = (value) => {

    if (value) {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const history = useNavigate();

  const [loading, setLoading] = useState(false);
  const LoginSchema = Yup.object().shape({
    // email: Yup.string()
    //   .email("Wrong email format")
    //   .min(3, "Minimum 3 symbols")
    //   .max(50, "Maximum 50 symbols"),
    // // .required(
    // //   intl.formatMessage({
    // //     id: "AUTH.VALIDATION.REQUIRED_FIELD",
    // //   })
    // // ),
    // username: Yup.string()
    //   .min(3, "Minimum 3 characters")
    //   .max(50, "Maximum 50 characters")
    //   .required
    //   // intl.formatMessage({
    //   //   id: "AUTH.VALIDATION.REQUIRED_FIELD",
    //   // })
    //   (),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required
      // intl.formatMessage({
      //   id: "AUTH.VALIDATION.REQUIRED_FIELD",
      // })
      (),
  });
  let location = useLocation();

  const [variant, setVariant] = useState("success");
  const [message, setMessage] = useState("");
  const [snak, setSnak] = React.useState(false);
  const [isPhnNumValue, setisPhnNumValue] = useState(null)
  function snakClose() {
    setSnak(false);
  }

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };
  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      if (initialValues.password == values.password && initialValues.username == values.username) {
        showUserDeleteError("Try with different username/password");
        return;
      }
      enableLoading();
      setTimeout(() => {
        setLoading(true)
        authLogin(values.password, values.username, org_instance_id, fetchCountryId)
          .then((resp) => {
            setLoading(false)
            // dispatch(Login(resp.data));
            if (resp.status === HTTP_CODE.ACCEPTED) {
              // toast.success("User Login successfully.!", {
              //   position: "top-right",
              //   autoClose: 2000,
              //   hideProgressBar: true,
              //   closeOnClick: true,
              // });
              // window.location.reload();
              localStorage.setItem("token", resp.data.data.access_token);
              localStorage.setItem("loginUserId", resp.data.data.user.id);
              localStorage.setItem("ref_token", resp.data.data.refresh_token);
              localStorage.setItem("postId", resp.data.data.posts[0].id);
              localStorage.setItem("session_id", resp.data.data.session_id);
              localStorage.setItem("persist:v713-demo1-auth", JSON.stringify(resp.data, null, 2));

              toast.success("User Login successfully.!", {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
              });
              // window.location.reload();

              disableLoading();
              // props.login(resp.data);

              onLoginSuccess(resp);
              // SuccessMessage({ message: "Invalid Verification Code!" });

              
            } else if (resp.status === HTTP_CODE.OK) {
              history("/auth" + resp.data.data.context, { state: resp.data.data });
            } else {
              initialValues.password = values.password;
              initialValues.username = values.username;

              showUserDeleteError("data");
            }
          })
          .catch((error) => {
            showUserDeleteError(error?.response?.data?.message ? error?.response?.data?.message :error?.message);
            // if(resp.status === HTTP_CODE.ACCEPTED){
            //   // window.location.reload();
            // }else if(resp.status == 401){
            //   alert("hiii")
            //   toast.error("Invalid User ID/Password", {
            //     position: "top-right",
            //     autoClose: 2000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //   });
              
            // }
           
            // // setLoading(true)
            // // toast.success("User Login successfully.!", {
            // //   position: "top-right",
            // //   autoClose: 2000,
            // //   hideProgressBar: true,
            // //   closeOnClick: true,
            // // });
            // // window.location.reload();
            // history("/auth" +resp.data.data.context, { state: resp.data.data });
            // initialValues.password = values.password;
            // initialValues.username = values.username;

          })
          .finally(() => {
            setLoading(false);
          });

      });
    },
  });
 

  function LoginSubmit() {
    formik.setFieldTouched("username");
    // if (formik.touched["username"] && !formik.errors["username"]) {
      formik.handleSubmit();
    // }
  }

  const handlechanged = (data) => {
    let isPhoneNumber = /([A-Za-z])/gm;

    let startdigit = /^\d/gm;
    let startCountryCodeSymbol = /^\+\d/gm;

    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (
      (!isPhoneNumber.test(data) && startdigit.test(data)) ||
      startCountryCodeSymbol.test(data)
    ) {
      setisPhnNumValue(data)
      setShowUsernameInput(false);
      setShowPhnnumInput(true);
    } else if (regEmail.test(data)) {
      setShowUsernameInput(true);
      setShowPhnnumInput(false);
      formik.setFieldValue("username", data);
    } else {
      setShowUsernameInput(true);
      setShowPhnnumInput(false);
      formik.setFieldValue("username", data);
    }
  };

  const handleOnNumChanged = (event) => {
    const phoneNumber = event.target.value;
    let regEmail = /@/;
    setPhoneNumber(phoneNumber);
    const extractedPhoneNumber = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters
    if (
      (!regEmail.test(phoneNumber))
    ){
      formik.setFieldValue('username', extractedPhoneNumber);
    }else{
      formik.setFieldValue('username', phoneNumber);
    }
  };

  
  const handleOnChanged = (...args) => {
    // ref.current.focus();
    if (args[3] == "") {
      setShowUsernameInput(true)
      setShowPhnnumInput(false);

    }
    // var countryCode = "+" + args[1].dialCode;
    if(args[1].dialCode == 91){
      var countryCode = "+" + args[1].dialCode;
    }else{
      var countryCode = args[1].dialCode;
    }
    var phnNum = args[0].match(/\d{10}$/);

    formik.setFieldValue("username", phnNum);
    // formik.setFieldValue("phn_nbr", phnNum);
    // formik.setFieldValue("country_id", countryCode);
    getCountryCode(countryCode);
  };
  const getCountryCode = (countryCode) => {
    getCountryList().then((res) => {
      var data = res.data.results;
      var matchid = countryCode;

      data.forEach(function (element) {
        if (element.dial_code == matchid) {
          setFetchCountryId(element.id);
        }
      });
    });
  };


  return (
    <>
      <div className="login-form login-signin" id="kt_login_signin_form">
        <div className="stu-logo-img">

          {/* <img src={toplogo} alt="Paris" style={{width:"100%"}}/> */}

        </div>
        <div className="text-left mb-10 " style={{ fontSize: "24px", fontWeight: "600" }}>

          {t('Welcome')}

          {/* <FormattedMessage id="AUTH.LOGIN.TITLE" /> */}
          {/* </h3> */}

        </div>
        <div style={{ textAlign: "center" }}>

          <img width={"100%"} style={{ marginBottom: "30px" }} src={props?.webConfig?.login_page_logo} />

        </div>
        {/* end::Head */}

        {/*begin::Form*/}
        <form
          onSubmit={LoginSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          {showUsernameInput ? (
            <div className="show-user-input">
              <div className="form-group fv-plugins-icon-container">
                {/* <div className=" react-tel-input form-control form-control-lg form-control-solid"> */}
                <input
                  placeholder={t(`Email / Mobile Number`)}
                  autoFocus="true"
                  autoComplete="off"
                  type="username"
                  style={{width:"-webkit-fill-available"}}
                  className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                    "username"
                  )}`}
                  name="username"
                  // {...formik.getFieldProps("username")}
                  onChange={(e) => handlechanged(e.target.value)}
                />

                {formik.touched.username && formik.errors.username ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.username}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          ) : null}
          {showPhnnumInput ? (
            <>
              {/* <Link
                style={{ color: "#2874f0" }}
                to="/auth/login"
                onClick={() => {
                  setShowUsernameInput(true);
                  setShowPhnnumInput(false);
                }}
              >
                {" "}
                {t(`Email / Mobile Number`)}
              </Link> */}
              {/* <div className="d-flex"> */}
              {/* <div className="show-phone-input"> */}
              <div className="show-user-input">
              <div className="form-group fv-plugins-icon-container ">
                {/* <ReactPhoneInput
                  className="form-control form-control-lg form-control-solid h-auto px-6 "
                  style={{width:"-webkit-fill-available"}}
                  autoCompleteSearch="true"
                  type="username"
                  name="username"
                  country={CountryDialCode}
                  // country={"gb"}
                  ref={phoneInputRef}
                  value={isPhnNumValue != null ? isPhnNumValue : null}
                  onChange={handleOnChanged}
                  disableDropdown={true}  // Add this line hide dropdown
                  inputProps={{

                    autoFocus: true,
                    autoComplete: true
                  }}
                />  */}
                 <input
                  placeholder={t(`Email / Mobile Number`)}
                    type="tel"
                    autoFocus="true"
                    className={`form-control form-control-solid h-auto py-5 px-6 `}
                    style={{ width: '-webkit-fill-available' }}
                    autoComplete="off"
                    value={phoneNumber}
                    // onChange={(e) => {
                    //   handleOnNumChanged(e);
                    //   formik.handleChange(e); 
                    // }}
                    onChange={(e) =>handleOnNumChanged(e)}
                    ref={phoneInputRef}
                   
                  />
                {formik.touched.username && formik.errors.username ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.username}
                    </div>
                  </div>
                ) : null}
              </div>
              </div>
            </>
          //   <div className="show-user-input">
          //   <div className="form-group fv-plugins-icon-container">
          //     {/* <div className=" react-tel-input form-control form-control-lg form-control-solid"> */}
          //     <input
          //       placeholder={t(`Email / Mobile Number`)}
          //       autoFocus="true"
          //       autoComplete="off"
          //       type="tel"
          //       style={{width:"-webkit-fill-available"}}
          //       className="form-control form-control-lg form-control-solid h-auto px-6"
          //       name="username"
          //       // {...formik.getFieldProps("username")}
          //       onChange={(e) => handlechanged(e.target.value)}
          //     />

          //     {formik.touched.username && formik.errors.username ? (
          //       <div className="fv-plugins-message-container">
          //         <div className="fv-help-block">
          //           {formik.errors.username}
          //         </div>
          //       </div>
          //     ) : null}
          //   </div>
          // </div>
          ) : null}

          <div className="form-group fv-plugins-icon-container">
            {/* <input
              placeholder="Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              onInput={() => formik.setFieldTouched("password", true)}
              {...formik.getFieldProps("password")}
            /> */}

            {/* <div className="pc-input-group"> */}
            {/* <div className="pc-input-group-prepend"> */}

            <input
              className="form-control form-control-solid h-auto py-5 px-6 "
              style={{width:"-webkit-fill-available"}}
              placeholder={t(`Password`)}
              // type="password"
              name="password"
              // onInput={() => formik.setFieldTouched("password", true)}
              // {...formik.getFieldProps("password")}

              type={passwordType}
              onChange={handlePasswordChange}
              value={passwordInput}
            />
            {/* <span className="pc-input-group-text">
            <div className="icon-color"><i className="fa fa-eye"></i></div>
          </span> */}
            {/* </div> */}
            {/* </div> */}


            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <small className="text-danger">{formik.errors.password} </small>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap justify-content-between align-items-center fs-14" >
            <div>
              <input type="checkbox" name="ShowPassword" onClick={(e) => togglePassword(e.target.checked)} />
              <label htmlFor="ShowPassword"> {t(`Show Password`)}</label></div>

            <Link
              to="/auth/forgot-password"
              // className="my-3 mr-2"
              id="kt_login_forgot"
              style={{ color: "#2874f0" }}
            >
              {t(`Forgot Password`)} ?
              {/* <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" /> */}
            </Link>


          </div>

          <LoadingButton
            style={{ width: "100%", padding: "12px" }}
            id="kt_login_signin_submit"
            type="submit"
            loading={loading}
            loadingPosition="center"
            loadingIndicator={<CircularProgress style={{ 'color': 'white', 'size': '10px' }} />}
            onClick={formik.handleSubmit}
            // disabled={formik.isSubmitting}
            className={`btn btn-diff-clr fw-bold px-9 py-4 my-3 login-button-sty-button `}
          >
            <span>{t(`Sign In`)}</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </LoadingButton>

          {
            location.pathname !== "/auth/email-verify" &&
            location.pathname !== "/auth/success-page" &&
            location.pathname !== "/auth/reset-password" &&
            location.pathname !== "/auth/registration" && (
              <div className="position-absolute top-0 fs-16 right-0 mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                <span className="fw-bold text-dark-50">
                  {t(`I don't have account yet`)}?
                </span>
                <Link
                  to="/auth/registration"
                  className="fw-bold ms-2 ml-rem"
                  id="kt_login_signup"
                  style={{ color: "#2874f0" }}
                >
                  {t(`Sign Up`)}!
                </Link>
              </div>
            )}
          {location.pathname === "/auth/registration" && (
            <div className="position-absolute top-0 fs-16 right-0 mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
              <span className="fw-bold text-dark-50">
                Already have an account?
              </span>
              <Link
                to="/auth/login"
                className="fw-bold ms-2"
                id="kt_login_signup"
              >
                Login now!
              </Link>
            </div>
          )}
        </form>

      </div>
      {/* </FadeIn> */}
    </>
  );
}

export default Login;
