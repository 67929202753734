import Axios from "axios";
import {language} from "../../../../src/helpers/Utils"


const defaultOptions = {
  headers: {
    "X-Authorization": ("Bearer " + localStorage.getItem("token")),
    "post-id": localStorage.getItem("postId"),
    "session-id": localStorage.getItem("session_id"),
    "service-instance-id": localStorage.getItem(
      "org_instance_id"
    ),
    region: "uk",
    organization: "eln",
    "Content-Type": "application/json",
    "Accept-Language": language()
    
  },
};

export const ORG_USER_ADDPHNNUM_VERIFY = "/Org/User/AddPhoneNbr/Verify/";
export const ORG_USER_ADDRESS_CREATE = "/Org/User/Address/Create/";
export const ORG_USER_ADDRESS_UPDATE = "/Org/User/AddressInfo/Update/";
export const ORG_USER_EMAIL_ADD = "/Org/User/Email/Add/";
export const ORG_USER_GET_USER_DETAIL = "/Org/User/GetUserDetail/";
export const STORE_PRODUCT_REVIEW_GET = "/Store/Product/Review/Get/"
export const GET_USER_LIST = "/Org/User/ListFetch";
export const ORG_USER_PERSONAL_INFO_UPDATE = "/Org/User/PersonalInfo/Update/";
export const ORG_USER_PHNNUM_ADD = "/Org/User/PhoneNumber/Add/";
export const ORG_USER_PRIVATE_SIGNUP = "/Org/User/PrivateSignup/";
export const ORG_USER_PROFILE_IMG_ADD = "/Org/User/ProfileImg/Add/?id=";
export const ORG_USER_PROFILE_IMG_ADD_WITHOUT_ID = "/Org/User/ProfileImg/Add/";
export const ORG_USER_REMOVE = "/Org/User/Remove/";
export const ORG_AUTH_CHANGE_PASSWORD = "/Org/Auth/ChangePassword/";
export const GET_COUNTRY = "/county/?country-id=";
export const GET_STATE = "/state/?country-id=";
export const GET_CITY = "/city/?country-id=";
export const GET_COUNTRY_BY_PAGE = "/country/?page_size=1000";
export const ORG_AUTH_VERIFICATION_INPROGRESS =
  "/Org/Auth/VerificationInProgress/";
export const ORG_AUTH_RESEND_OTP = "/Org/Auth/ResendOTP/";
export const ORG_USER_USER_DETAIL_LIST="/Org/User/UserDetail/List/"
export const ORG_AUTH_RESEND_VERIFICATION_EMAIL="/Org/Auth/ResendVerificationEmail/";


// store order review
export const STORE_ORDER_REVIEW = "/Store/Order/Review/Get/"
// student scholarship
export const STU_EDUCATION_DETAIL = "/Student/EducationDetails/"
export const STU_FAMILY_DETAIL = "/Student/FamilyDetails/"
export const STU_PROFILE_DETAIL = "/Student/ProfileDetails/"
export const STU_PROPERTY_DETAIL = "/Student/PropertyDetails/"
export const STU_SCHOLARSHIP = "/Student/Schlorship/"
export const STU_UPDATE_PROFILE_DETAIL = "/Student/ProfileDetails/update_profile_details/"

export const STU_PROFILE_DETAIL_USER = "/Student/ProfileDetails/get_user_profile_details/?user="
export const STU_EDUCATION_DETAIL_USER = "/Student/EducationDetails/get_education_details/?user="
export const STU_FAMILY_DETAIL_USER = "/Student/FamilyDetails/get_family_details/?user="
export const STU_PROPERTY_DETAIL_USER = "/Student/PropertyDetails/get_property_details/?user="
export const STU_SCOLARSHIP_DETAIL_USER = "/Student/Schlorship/get_scholarship_details/?user="


export function getStuProfileDetailListByUserId(id) {
  return Axios.get(STU_PROFILE_DETAIL_USER + id );
}
export function getStuEducationDetailListByUserId(id) {
  return Axios.get(STU_EDUCATION_DETAIL_USER + id );
}
export function getStuFamilyDetailListByUserId(id) {
  return Axios.get(STU_FAMILY_DETAIL_USER + id );
}
export function getStuPropertyDetailListByUserId(id) {
  return Axios.get(STU_PROPERTY_DETAIL_USER + id );
}
export function getStuScholarshipDetailListByUserId(id) {
  return Axios.get(STU_SCOLARSHIP_DETAIL_USER + id );
}



// student education
export function createStuEducationDetails(values,instituteType,fetchCountryId,id) {
  
  return Axios.post( STU_EDUCATION_DETAIL,{
    institution_type:instituteType,
    qualification:values.qualification,
    department:values. department,
    year:values. year,
    institution_name:values. institution_name,
    medium:values. medium,
    status:values. status,
    percentage:values. percentage,
    address_line1:values. address_line1,
    address_line2:values. address_line2,
    city:values. city,
    taluk:values. taluk,
    district:values. district,
    country_code:fetchCountryId,
    phn_nbr:values. phn_nbr[0],
    user: id,

  });
}
export function getStuEducationDetailList() {
  return Axios.get(STU_EDUCATION_DETAIL );
}
export function getStuEducationDetailListById(id) {
  return Axios.get(STU_EDUCATION_DETAIL + id + "/" );
}

export function UpdateStuEducationDetail(id,values,fetchCountryId,userid) {
  return Axios.patch(STU_EDUCATION_DETAIL + id + "/", {
    institution_type:values.institution_type,
    qualification:values.qualification,
    department:values. department,
    year:values. year,
    institution_name:values. institution_name,
    medium:values. medium,
    status:values. status,
    percentage:values. percentage,
    address_line1:values. address_line1,
    address_line2:values. address_line2,
    city:values. city,
    taluk:values. taluk,
    district:values. district,
    country_code:fetchCountryId,
    phn_nbr:values. phn_nbr[0],
    // user: userid,
  });
}
export function removeStuEducationDetail(id) {
  return Axios.delete(STU_EDUCATION_DETAIL + id + "/" );
}

// student family
export function createStuFamilyDetails(values) {
  return Axios.post( STU_FAMILY_DETAIL,values);
}
export function getStuFamilyDetailList() {
  return Axios.get(STU_FAMILY_DETAIL );
}
export function getStuFamilyDetailListById(id) {
  return Axios.get(STU_FAMILY_DETAIL + id + "/" );
}

export function UpdateStuFamilyDetail(id,values) {
  return Axios.patch(STU_FAMILY_DETAIL + id + "/", values);
}
export function removeStuFamilyDetail(id) {
  return Axios.delete(STU_FAMILY_DETAIL + id + "/" );
}

// student profile

export function createStuProfileDetails(values) {
  return Axios.post( STU_PROFILE_DETAIL,values);
}
export function getStuProfileDetailList() {
  return Axios.get(STU_PROFILE_DETAIL );
}
export function getStuProfileDetailListById(id) {
  return Axios.get(STU_PROFILE_DETAIL + id + "/" );
}

export function UpdateStuProfileDetail(id,values) {
  return Axios.patch(STU_PROFILE_DETAIL + id + "/", values);
}
export function removeStuProfileDetail(id) {
  return Axios.delete(STU_PROFILE_DETAIL + id + "/" );
}

// student property

export function createStuPropertyDetails(values) {
  return Axios.post( STU_PROPERTY_DETAIL,values);
}
export function getStuPropertyDetailList() {
  return Axios.get(STU_PROPERTY_DETAIL );
}
export function getStuPropertyDetailListById(id) {
  return Axios.get(STU_PROPERTY_DETAIL + id + "/" );
}

export function UpdateStuPropertyDetail(id,values) {
  return Axios.patch(STU_PROPERTY_DETAIL + id + "/", values);
}
export function removeStuPropertyDetail(id) {
  return Axios.delete(STU_PROPERTY_DETAIL + id + "/" );
}

// student scholarship
export function createStuScholarshipDetails(values) {
  return Axios.post( STU_SCHOLARSHIP,values);
}
export function getStuScholarshipDetailList() {
  return Axios.get(STU_SCHOLARSHIP );
}
export function getStuScholarshipDetailListById(id) {
  return Axios.get(STU_SCHOLARSHIP + id + "/" );
}

export function UpdateStuScholarshipDetail(id,values) {
  return Axios.patch(STU_SCHOLARSHIP + id + "/", values);
}
export function removeStuScholarshipDetail(id) {
  return Axios.delete(STU_SCHOLARSHIP + id + "/" );
}




export function resendVerificationEmail(email,firm) {
  return Axios.post(ORG_AUTH_RESEND_VERIFICATION_EMAIL ,{ email: email, firm: firm } );
}

export function getUserDetailList(node_id,currentPage) {
  return Axios.get(ORG_USER_USER_DETAIL_LIST + "?node_id=" + node_id  );
}

export function createProfileImage(id) {
  return Axios.post(ORG_USER_PROFILE_IMG_ADD +  id);
}


export function userPhnNumVerify(phn_nbr, OTP) {
  return Axios.post(ORG_USER_ADDPHNNUM_VERIFY, { phn_nbr: phn_nbr, code: OTP });
}




export function resendOtp(phn_nbr, firm) {
  return Axios.post(ORG_AUTH_RESEND_OTP, {
    phn_nbr: phn_nbr,
    firm: parseInt(firm),
  });
}

export function verificationInprogress(code) {
  return Axios.get(ORG_AUTH_VERIFICATION_INPROGRESS + code +"/");
}

export function createProfileImg( image) {
  return Axios.post(ORG_USER_PROFILE_IMG_ADD_WITHOUT_ID , {
    image: image,
  });
}


export function createEmail(email) {
  return Axios.post(ORG_USER_EMAIL_ADD, {
    email: email,
  });
}
export function createPhnNbr(phn_nbr, country_id) {
  return Axios.post(ORG_USER_PHNNUM_ADD, {
    phn_nbr: phn_nbr[0],
    country_id: country_id,
  });
}
export function getCountryPg() {
  return Axios.get(GET_COUNTRY_BY_PAGE);
}
export function getCountyList(id) {
  return Axios.get(GET_COUNTRY + id);
}

export function getStateList(id) {
  return Axios.get(GET_STATE + id);
}
export function getCityList(countryId, stateId, countyId) {
  return Axios.get(
    GET_CITY + countryId + "&state-id=" + stateId + "&county-id=" + countyId
  );
}

export function updateUserAddInfo(id, value) {
  return Axios.patch(ORG_USER_ADDRESS_UPDATE + id + "/", value);
}
export function createUserAddInfo(value) {
  return Axios.post(ORG_USER_ADDRESS_CREATE, value);
}
export function updatePassword(value) {
  return Axios.patch(ORG_AUTH_CHANGE_PASSWORD, value);
}

export function updateUserPersonalInfo(id, value) {
  return Axios.patch(ORG_USER_PERSONAL_INFO_UPDATE + "?id=" + id, value);
}

// get users list with pagination

export function getUserList(page) {
  return Axios.get(GET_USER_LIST + page);
}

export function getAllUserList(id) {
  return Axios.get(ORG_USER_GET_USER_DETAIL + "?user_id="+ id );
}

// store order review get methods

export function getOrderReview(id){
  return Axios.get(STORE_ORDER_REVIEW + id + "/")
}
export function getCustomerReview(id){
  return Axios.get(STORE_PRODUCT_REVIEW_GET + id + "/" );
} 
// get single user
export function getSingleUser(id) {
  return Axios.get(ORG_USER_PRIVATE_SIGNUP + id + "/");
}

// add new user
export function postUser(values) {
  return Axios.create(defaultOptions).post(ORG_USER_PRIVATE_SIGNUP, values);
}

// update a user
export function patchUser(id, values) {
  return Axios.patch(ORG_USER_PRIVATE_SIGNUP + id + "/", values);
}

// delete user
export function deleteUser(id) {
  return Axios.delete(ORG_USER_REMOVE + id + "/");
}

export const HTTP_CODE = {
  ACCEPTED: 202,
  BADREQUEST: 400,
  CONFLICT: 409,
  CREATED: 201,
  OK: 200,
};


export const ORG_SERVICES_SERVICEINSTANCE_LIST =
  "Org/Services/ServiceInstances/List/";

export function gerServiceInstanceList() {
  return Axios.get(ORG_SERVICES_SERVICEINSTANCE_LIST);
}